import {LegacyDoorInterface} from 'components/customer/Materials/helper/doorHelper';
import {LegacyEdgeInterface} from 'components/customer/Materials/helper/edgeHelper';
import {LegacyMaterialInterface} from 'components/customer/Materials/helper/materialHelper';
import {DrawerSystem, HingeStyle} from 'components/customer/Product/entity';
import {CabinetTop} from 'shared/types/CabinetTop';
import {ProductSize} from 'shared/types/ProductSize';
import {GapSize} from 'shared/types/GapSize';
import {AppApi} from 'store/customer/api';
import {
    JobStatusData,
    mapJobStatusDefaults,
} from 'components/manufacturer/Preferences/store/preferencesApi';
import {Country} from 'components/customer/Auth/entity/country';

enum FreightOption {
    FREIGHT_SPECIFIC_ADDRESS = 'FREIGHT_SPECIFIC_ADDRESS',
    FREIGHT_MY_ADDRESS = 'FREIGHT_MY_ADDRESS',
}

interface DoorHingeHole {
    amount: number;
    height: number;
}

export enum UserType {
    ADMIN = 0,
    MANUFACTURER = 1,
    CUSTOMER = 2,
}

export enum MaterialSelector {
    SEARCH = 0,
    DROPDOWNS = 1,
}

interface Help {
    id: number;
    manufacturerId: number;
    optionName: string;
    optionUrl: string;
}

export interface User {
    abn?: string;
    acn?: string;
    addDefaultRoomOnNewJobCreate: number;
    room_assistant: number;
    address: string;
    address2?: string;
    addressState: number;
    allowCustomerInvoicingPdf: number;
    businessName?: string;
    cc: string;
    countryName: string;
    currencyType: string;
    defaultAdjustableLegs: number;
    defaultAdvancedBarWidth: number;
    defaultAdvancedEdgeBottom: number;
    defaultAdvancedEdgeLeft: number;
    defaultAdvancedEdgeRight: number;
    defaultAdvancedEdgeTop: number;
    defaultAdvancedRailHeight: number;
    defaultAdvancedRailWidth: number;
    defaultBorderWidthBottom: number;
    defaultBorderWidthLeft: number;
    defaultBorderWidthRight: number;
    defaultBorderWidthTop: number;
    defaultCarcBrand: number;
    defaultCarcCustomColor?: string;
    defaultCarcDoubleSided?: number;
    defaultCarcEdge: number;
    defaultCarcEdgeBrand: number;
    defaultCarcEdgeFinish: string;
    defaultCarcFinish: string;
    defaultCarcHorGrain?: number;
    defaultCarcMaterial: number;
    defaultCarcMaterialType: number;
    defaultCarcSubstrate: string;
    defaultDepotId?: number;
    depotSelectable?: number;
    isDepotFunctionalityEnabled?: number;
    depotIdentification?: string;
    defaultDoor: number;
    defaultDoorCategory?: number;
    defaultDrawer: number;
    defaultExcludeHardware: number;
    defaultExtBrand: number;
    defaultExtCustomColor?: string;
    defaultExtDoubleSided?: number;
    defaultExtEdge: number;
    defaultExtEdgeBrand: number;
    defaultExtEdgeFinish: string;
    defaultExtFinish: string;
    defaultExtHorGrain?: number;
    defaultExtMaterial: number;
    defaultExtMaterialType: number;
    defaultExtSubstrate: string;
    defaultFreightOption: FreightOption;
    defaultGap: number;
    defaultHinge: number;
    defaultIncludeAssembly: number;
    defaultIncludeHardware: number;
    defaultJobPropertiesPdf: number;
    defaultMeasurementUnit: string;
    defaultRoomTop: number;
    defaultShelfSetBack: number;
    defaultSize: number;
    defaultToeKickHeight: number;
    defaultUserUiPreference: number;
    deliveryAddress: string;
    deliveryPostcode: number;
    deliveryState: number;
    deliverySuburb: string;
    domain: string;
    doorHingeHoles: DoorHingeHole[];
    email: string;
    favouriteSundries: boolean;
    homePage: string;
    id: number;
    inActiveManufacturer: number;
    isAddProductAvailable: number;
    materialSelector: MaterialSelector;
    isBetaCustomer: boolean;
    isAddRoomAvailable: number;
    isAllowedNonmmUnits: number;
    isAllowedToEditInfoText: boolean;
    isBTDAvailable: boolean;
    isBenchtopAvailable: boolean;
    isDefaultDoorAdvanced: number;
    isHardwareInclusionVisible: boolean;
    isKDMaxImportAvailable: number;
    isQFPAvailable: number;
    isSundryAvailable: number;
    isSupplyMethodVisible: boolean;
    isAssemblyOnly: boolean;
    isSuspended: number;
    isBanned: number;
    isTDLDAvailable: number;
    jobSubmitTextNoVariations: string;
    jobSubmitTextVariationsAccepted: string;
    jobSubmitTextWithVariations: string;
    level: number;
    manuPermission: number;
    manufacturerAddress: string;
    manufacturerContactInfo: string;
    manufacturerDefaultHingePosition: number;
    manufacturerEmail: string;
    manufacturerFavicon: string;
    manufacturerFreightAvailable: number;
    manufacturerFreightText: string;
    manufacturerHelpAndSupportDefaultOptions: Help[];
    manufacturerId: number;
    manufacturerLogo: string;
    manufacturerName: string;
    manufacturerNews: string;
    manufacturerPhone: string;
    manufacturerPickupAvailable: number;
    manufacturerPostCode: number;
    manufacturerState: number;
    manufacturerSuburb: string;
    manufacturerAllowCustomerEdit: boolean;
    minCharge: number;
    mobile?: string;
    name: string;
    newUiPolicy: 'classic' | 'switchable' | 'force' | 'newui';
    phNumber: string;
    postcode: number;
    premium: number;
    allowFileUpload: boolean;
    qFPMenuText: string;
    showJobPropertiesTablePdf: number;
    showOrderAcknowledgementPdf: number;
    suburb: string;
    sundryMenuText: string;
    theme: string;
    user_type: number;
    websiteUrl?: string;
    analyticsMetrics?: Record<string, string>;
    tutorialOptions: null | Record<string, boolean>;
    is_room_assistant_available?: number;
    isAdjustableLegsVisible: boolean;
    show_pricing: boolean;
    job?: {
        room: {
            carcaseEdgeMaterial?: LegacyEdgeInterface;
            carcaseMaterial?: LegacyMaterialInterface;
            door?: LegacyDoorInterface;
            exteriorEdgeMaterial?: LegacyEdgeInterface;
            exteriorMaterial?: LegacyMaterialInterface;
            hingeStyle?: HingeStyle;
            cabinetTop?: CabinetTop;
            drawerSystem?: DrawerSystem;
            productSize?: ProductSize;
            sizeDefaults?: SizeDefaultLegacy;
            gapSize?: GapSize;
            gapDefaults?: GapDefaultLegacy;
        };
    };
    createJobLandingPage?: number;
    jobStatuses?: JobStatusData[];
    simpleJobWorkflow?: number;
    allowDeliveryDateRequest?: boolean;
    allowDrawerFaceEdgeFinish?: boolean;
    minimumLeadTime?: number;
    maxAgeJobPrice?: number;
    requestedDeliveryDateFrequency?: number;
    allowDirectLogin?: boolean;
    parentApplicationUrl?: string;
    parentApplicationName?: string;
    allow3DView?: boolean;
    allowNotchesAndClips?: boolean;
    requireDeliveryDateRequest?: boolean;
}

interface SizeDefaultLegacy {
    id: number;
    customer_id: number;
    user_level: number;
    name: string;
    base_depth: number;
    base_height: number;
    tall_depth: number;
    tall_height: number;
    upper_depth: number;
    upper_height: number;
}

interface GapDefaultLegacy {
    name: string;
    id: number;
    customer_id: number;
    user_level: number;
    base_bottom_gap: number;
    base_top_gap: number;
    door_gap: number;
    drawer_gap: number;
    left_gap: number;
    right_gap: number;
    upper_bottom_gap: number;
    upper_top_gap: number;
}

const mapProductSize = (
    legacyDefault?: SizeDefaultLegacy
): ProductSize | null => {
    if (legacyDefault) {
        return {
            id: legacyDefault.id,
            baseDepth: legacyDefault.base_depth,
            baseHeight: legacyDefault.base_height,
            customerId: legacyDefault.customer_id,
            name: legacyDefault.name,
            tallDepth: legacyDefault.tall_depth,
            tallHeight: legacyDefault.tall_height,
            upperDepth: legacyDefault.upper_depth,
            upperHeight: legacyDefault.upper_height,
            userLevel: legacyDefault.user_level,
        };
    }

    return null;
};

const mapGapSize = (legacyDefault?: GapDefaultLegacy): GapSize | null => {
    if (legacyDefault) {
        return {
            id: legacyDefault.id,
            name: legacyDefault.name,
            customerId: legacyDefault.customer_id,
            userLevel: legacyDefault.user_level,
            baseBottomGap: legacyDefault.base_bottom_gap,
            baseTopGap: legacyDefault.base_top_gap,
            doorGap: legacyDefault.door_gap,
            drawerGap: legacyDefault.drawer_gap,
            left: legacyDefault.left_gap,
            right: legacyDefault.right_gap,
            upperBottomGap: legacyDefault.upper_bottom_gap,
            upperTopGap: legacyDefault.upper_top_gap,
        };
    }

    return null;
};

const mapJobDefaults = (
    job?: User['job']
): Pick<User, 'job'> | Record<string, never> => {
    if (job) {
        return {
            job: {
                room: {
                    ...job.room,
                    productSize: mapProductSize(job.room.sizeDefaults),
                    gapSize: mapGapSize(job.room.gapDefaults),
                },
            },
        };
    }

    return {};
};

export const UserApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query<User, void>({
            query: () => 'user',
            transformResponse: (response: {user: User}) => {
                const {jobStatuses, simpleJobWorkflow, job, allow3DView} =
                    response.user;
                return {
                    ...response.user,
                    ...mapJobDefaults(job),
                    allow3DView: window.WebGL2RenderingContext
                        ? allow3DView
                        : false,
                    jobStatuses: mapJobStatusDefaults(
                        jobStatuses,
                        simpleJobWorkflow
                    ),
                };
            },
            providesTags: ['User'],
        }),
        getCountries: builder.query<Country[], void>({
            query: () => 'countries',
            transformResponse: (response: {Countries: Country[]}) => {
                return response.Countries;
            },
            providesTags: ['Countries'],
        }),
    }),
});

export const invalidateUser = () => UserApi.util.invalidateTags(['User']);

export const {useGetUserQuery, useGetCountriesQuery} = UserApi;
